import React from "react";
import Timeline from "react-calendar-timeline";
import styled from "styled-components"; // Import styled-components
import "../css/calendarTimeline.css"; // Import the custom CSS file
import { Content } from "./styledComponents/ui";

// Define the styled components
const StyledCalendarItem = styled.div`
  width: 100%;
  background-color: #292c2c; /* Update to the dark main color */
  color: white; /* Text color for better visibility */
  text-align: center;
  border-radius: 5px;
  padding: 2px;
`;

const StyledFillBox = styled.div`
  position: relative;
  background-color: #f39c12; /* Change to desired color */
  opacity: 0.3; /* Adjust opacity as needed */
  height: 100%;
  z-index: 10;
`;

const CalendarComponent = ({ currentTime }) => {
  const formatTime = (time) => {
    const hours = Math.floor(time / 3600000);
    const minutes = Math.floor((time % 3600000) / 60000);
    const seconds = Math.floor((time % 60000) / 1000);

    return `${hours}:${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const toTimelineFormat = (timestamp) => new Date(timestamp).toISOString();

  const currentEndTime = new Date(currentTime);

  const groups = [{ id: 1, title: "" }];

  const items = [
    {
      id: 1,
      group: 1,
      start_time: currentTime,
      end_time: currentEndTime,
      canResize: false,
      title: `Current Time: ${formatTime(currentTime)}`,
      className: "calendar-item",
    },
  ];

  // Calculate visibleTimeStart and visibleTimeEnd for a minute interval timeline
  const intervalDuration = 60000; // 1 minute in milliseconds
  const visibleTimeStart = new Date(
    Math.floor(currentTime / intervalDuration) * intervalDuration
  );
  const visibleTimeEnd = new Date(
    visibleTimeStart.getTime() + 24 * 60 * intervalDuration
  ); // Show 24 hours of minutes

  // Calculate the elapsed time in milliseconds
  const elapsed = currentTime - visibleTimeStart.getTime();

  const boxWidth = (elapsed / (24 * 60 * intervalDuration)) * 100;

  return (
    <div>
      <Timeline
        groups={groups}
        items={items}
        visibleTimeStart={visibleTimeStart}
        visibleTimeEnd={visibleTimeEnd}
        lineHeight={30} // Increase the line height to make the timeline taller for minutes
        itemHeightRatio={1.0} // Set item height ratio to 1 for minute intervals
        sidebarWidth={0} // Increased sidebar width to fit the additional details
        itemRenderer={(props) => (
          <StyledCalendarItem {...props}>{props.item.title}</StyledCalendarItem>
        )}
      />
    </div>
  );
};

export default CalendarComponent;
