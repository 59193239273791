import styled, { keyframes } from "styled-components";
import ChargemediaTheme from "../../ChargemediaTheme";

const { colors, timings, padding } = ChargemediaTheme;
const footerHeight = "50px";

export const ContentBody = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - ${footerHeight});
  margin: 0;
  margin-bottom: 50px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const ContentRow = styled.div`
  display: flex;
  flex-direction: row;
  background-color: transparent;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const ContentInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: ${colors.darkGrey};
  align-items: center;
  width: 90%;
  height: 100%;
  overflow-y: auto;
  /* Hide scrollbar but keep functionality - Firefox */
  scrollbar-width: none;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;

  /* Hide scrollbar - WebKit (Safari and Chrome) */
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const OverViewBox = styled.div`
  display: flex;
  margin: 10px;
  flex-direction: column;
  background-color: ${colors.darkGrey};
  border: 2px solid ${colors.lightGrey};
  width: 90%;
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: row;
  font-size: 20px;
  justify-content: space-between;
  border-radius: 0 0 0 0;
  background-color: ${colors.lightGrey};
  color: ${colors.darkGrey};
  align-items: center;
  width: 100%;
  height: ${footerHeight};
  margin: 0;
`;

export const InputLogin = styled.input`
  width: 94%;
  padding: 10px;
  margin: 5px 0;

  border: 1px solid #ccc;
`;

export const Input = styled.input`
  padding: 10px;
  height: 18px;
  margin: 5px 0;
  background-color: ${colors.accentGrey};
  border: 1px solid #ccc;
`;

export const Button = styled.button`
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: ${colors.darkGrey};
  border: none;
  color: ${colors.lightGrey};
  font-weight: bold;
  cursor: pointer;
  transition: ${timings.hover};
  &:hover {
    background-color: ${colors.lightGrey};
    color: ${colors.darkGrey};
  }
`;

export const ButtonLight = styled.button`
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  background-color: ${colors.lightGrey};
  border: none;
  color: ${colors.darkGrey};
  font-weight: bold;
  cursor: pointer;
  transition: ${timings.hover};
  &:hover {
    background-color: ${colors.darkGrey};
    color: ${colors.lightGrey};
  }
`;

export const ButtonSmallLight = styled.button`
  padding: 10px;
  margin: 10px;
  bottom: ${padding.small};
  font-size: 20px;

  background-color: ${colors.green};
  border: none;
  color: ${colors.darkGrey};
  cursor: pointer;
  transition: ${timings.hover};
  &:hover {
    background-color: ${colors.greenHover};
    color: ${colors.lightGrey};
  }
  &:disabled {
    background-color: ${colors.lightGrey};
    color: ${colors.darkGrey};
    cursor: not-allowed;
  }
`;

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

// Styled component for the rotating circle
export const RotatingCircle = styled.div`
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  border-top: 2px solid ${colors.red};
  border-radius: 50%;
  animation: ${rotate} 0.7s linear infinite;

  /* Center the circle horizontally and vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  border: 1px solid ${colors.lightGrey};
  cursor: pointer;

  tr {
    transition: ${timings.hover};
  }

  th,
  td {
    padding: 8px;
    border: 1px solid #ccc;
  }

  th {
    background-color: ${colors.darkGrey};
    font-weight: bold;
  }

  tr:nth-child(even) {
    background-color: abackground-color: ${colors.darkGrey};
  }

  tr:nth-child(even):hover {
    background-color: ${colors.lightGrey};
    color: ${colors.darkGrey};
  }
  tr:nth-child(odd):hover {
    background-color: ${colors.lightGrey};
    color: ${colors.darkGrey};
  }
`;
