import React from "react";
import { auth } from "../firebase";
import { Button } from "./styledComponents/ui";
function Logout() {
  const handleLogout = async () => {
    try {
      await auth.signOut();
      // User logged out successfully
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  return (
    <div style={{ marginLeft: "15px", marginBottom: "5px" }}>
      <Button onClick={handleLogout}>Logout</Button>
    </div>
  );
}

export default Logout;
