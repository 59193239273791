import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { auth } from "./firebase"; // Import the auth object
import { ThemeProvider } from "styled-components"; // Import the ThemeProvider
import Login from "./components/Login";
import Hub from "./components/pages/Hub";
import ChargemediaTheme from "./ChargemediaTheme";
import { Dashboard } from "@mui/icons-material";
const App = () => {
  const [user, setUser] = useState(null);
  const [authChecked, setAuthChecked] = useState(false);
  // Check user authentication status on component mount
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
      setAuthChecked(true); // Set authChecked to true when auth state is determined
    });

    return () => unsubscribe();
  }, []);

  if (!authChecked) {
    return null; // Return nothing until auth state is checked
  }
  return (
    <ThemeProvider theme={ChargemediaTheme}>
      <Router>
        <Routes>
          <Route
            path="/"
            element={user ? <Navigate to="/hub" /> : <Navigate to="/login" />}
          />
          <Route
            path="/login"
            element={user ? <Navigate to="/hub" /> : <Login />}
          />
          <Route
            path="/hub"
            element={
              user ? <Hub page={"dashboard"} /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/hub/c"
            element={
              user ? <Hub page={"customer"} /> : <Navigate to="/login" />
            }
          />
          <Route
            path="/hub/c/:id"
            element={
              user ? (
                <Hub page={"customerOverview"} />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
          <Route
            path="/hub/c/:id/pj/:id"
            element={
              user ? <Hub page={"customerProject"} /> : <Navigate to="/login" />
            }
          />
        </Routes>
      </Router>
    </ThemeProvider>
  );
};

export default App;
