import React, { useState, useEffect } from "react";
import { auth, firestore } from "../../../firebase";
import Logout from "../../Logout";
import { Content, OverViewBox } from "../../styledComponents/ui";
import BoxContentUI from "../../BoxContentUI";
import TableList from "../../TableList";
import TimeTracker from "../../TimeTracker";
import TimeTrackerComp from "../../ui/TimeTrackerComp";
const CustomerProject = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [customerData, setCustomerData] = useState(null);
  const [customerPjData, setCustomerPjData] = useState(null);
  const [firstId, setFirstId] = useState(null);
  const [secondId, setSecondId] = useState(null);
  const getIdsFromURL = () => {
    const url = window.location.href;
    const parts = url.split("/");
    const idIndexes = [];
    for (let i = 0; i < parts.length; i++) {
      if (parts[i] === "c" || parts[i] === "pj") {
        idIndexes.push(i + 1);
      }
    }
    return idIndexes.length >= 2
      ? [parts[idIndexes[0]], parts[idIndexes[1]]]
      : [null, null];
  };

  const [firstIdFromURL, secondIdFromURL] = getIdsFromURL();
  const customerRef = firestore.collection("customers").doc(firstIdFromURL);
  const customerPjRef = firestore
    .collection("customers")
    .doc(firstIdFromURL)
    .collection("projects")
    .doc(secondIdFromURL);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });

    setFirstId(firstIdFromURL);
    setSecondId(secondIdFromURL);

    if (firstIdFromURL) {
      customerRef.get().then((doc) => {
        if (doc.exists) {
          setCustomerData(doc.data());
        } else {
          console.log("Document not found");
        }
      });
    }

    if (secondIdFromURL) {
      customerPjRef.get().then((doc) => {
        if (doc.exists) {
          setCustomerPjData(doc.data());
        } else {
          console.log("Project Document not found");
        }
      });
    }

    return () => {
      unsubscribe();
    };
  }, []);
  console.log("Customer Data:", customerPjData);
  return (
    <Content>
      <h1>Kunden OVERVIEW</h1>
      {customerData && (
        <div>
          {customerPjData && (
            <div>
              <p>Project Name: {customerPjData.name}</p>
              {/* Display other project data */}
            </div>
          )}
        </div>
      )}
      {secondId && customerPjData && (
        <BoxContentUI
          title={customerPjData.name}
          isEditable={false}
          DocID={secondId}
        >
          <TimeTracker
            timerData={customerPjData.timerData}
            customerPjRef={customerPjRef}
          />
        </BoxContentUI>
      )}
      {secondId && customerPjData && (
        <BoxContentUI title="Timer Data" isEditable={false} DocID={secondId}>
          <TimeTrackerComp
            timerData={customerPjData.timerData}
            customerPjRef={customerPjRef}
          />
        </BoxContentUI>
      )}
    </Content>
  );
};

export default CustomerProject;
