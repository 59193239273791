import React, { useState } from "react";
import { OverViewBox } from "./styledComponents/ui";
import { ButtonSmallLight } from "./styledComponents/ui";
import { Input, ButtonLight, RotatingCircle } from "./styledComponents/ui";
import ChargemediaTheme from "../ChargemediaTheme";
import { firestore } from "../firebase";
const { colors, padding } = ChargemediaTheme;

const BoxContentUI = ({ children, title, isEditable, DocID }) => {
  const [projectName, setProjectName] = useState("");
  const [showNewProjectInputs, setShowNewProjectInputs] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const handleNewProjectClick = () => {
    setShowNewProjectInputs(true);
  };

  const handleProjectNameChange = (e) => {
    setProjectName(e.target.value);
  };

  const handleSaveProject = async () => {
    if (projectName.trim() === "") {
      setError("Project name cannot be empty");
      return;
    }

    setLoading(true);
    setError("");

    try {
      const projectsCollection = firestore
        .collection("customers")
        .doc(DocID)
        .collection("projects"); // Replace 'projects' with your collection name

      const newProjectData = {
        name: projectName,
      };

      await projectsCollection.add(newProjectData);

      setProjectName("");
      setLoading(false);
      setShowNewProjectInputs(false);

      window.location.reload();
    } catch (err) {
      setError("An error occurred while saving the project.");
      setLoading(false);
      console.error(err);
    }
  };
  console.log(DocID);
  return (
    <OverViewBox>
      <p style={{ margin: "5px" }}>{title}</p>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          borderBottom: `1px solid ${colors.lightGrey}`,
          borderRadius: "0px",
          width: "100%",
        }}
      ></div>

      {children}
      {isEditable && (
        <>
          <ButtonSmallLight onClick={handleNewProjectClick}>
            Neues Projekt
          </ButtonSmallLight>
          {showNewProjectInputs && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                border: `1px solid ${colors.lightGrey}`,
                borderRadius: "5px",
                padding: "10px",
                marginTop: "10px",
              }}
            >
              <label htmlFor="projectName">Project Name:</label>
              <Input
                type="text"
                id="projectName"
                value={projectName}
                onChange={handleProjectNameChange}
              />

              {loading ? (
                <RotatingCircle /> // Display the rotating circle when loading
              ) : (
                <ButtonLight onClick={handleSaveProject}>Speichern</ButtonLight>
              )}
              {error && (
                <p style={{ color: colors.red, margin: padding.small }}>
                  {error}
                </p>
              )}
            </div>
          )}
        </>
      )}
    </OverViewBox>
  );
};

export default BoxContentUI;
