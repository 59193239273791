// styledComponents/StyledLogin.js
import styled, { keyframes } from "styled-components";

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

export const Box = styled.div`
  width: 400px;
  padding: 20px;
  border: 2px solid #ccc;
  background-color: #f9f9f9;
`;
