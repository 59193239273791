import styled from "styled-components";
import ChargemediaTheme from "../../ChargemediaTheme";

const { colors, timings } = ChargemediaTheme;

export const ListMenuStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
  background-color: ${colors.darkGrey};
  align-items: left;
  border-radius: 0px;
  border-right: 1px solid ${colors.lightGrey};
  width: 10%;
  height: 100%;
  overflow: hidden;
`;

export const StyledMenuItem = styled.div`
  width: 100%;
  padding: 10px;
  margin-top: 0px;
  background-color: ${colors.darkGrey};
  border: none;
  border-radius: 0px;
  color: white;
  cursor: pointer;
  text-decoration: none !important;

  transition: ${timings.hover};
  &:hover {
    background-color: ${colors.lightGrey};
    color: ${colors.darkGrey};
  }
`;
