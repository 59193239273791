import React, { useState, useEffect } from "react";
import { auth, firestore } from "../../../firebase";
import Logout from "../../Logout";
import TableList from "../../TableList";
import { Content } from "../../styledComponents/ui";
import {
  ButtonSmallLight,
  Input,
  RotatingCircle,
  ButtonLight,
} from "../../styledComponents/ui";
import ChargemediaTheme from "../../../ChargemediaTheme";

const { colors, padding } = ChargemediaTheme;

const Customer = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [customerData, setCustomerData] = useState([]);
  const [customerName, setCustomerName] = useState("");
  const [showNewCustomerInputs, setshowNewCustomerInputs] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });

    const fetchCustomerData = async () => {
      try {
        if (currentUser) {
          const customerCollection = firestore.collection("customers");
          const snapshot = await customerCollection.get();
          const data = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setCustomerData(data);
        }
      } catch (error) {
        console.error("Error fetching customer data:", error);
      }
    };

    fetchCustomerData();

    return () => {
      unsubscribe();
    };
  }, [currentUser]);

  const tableColumns = [
    { header: "Name", field: "name" },

    // Add more columns as needed
  ];

  const handleNewCustomerClick = () => {
    setshowNewCustomerInputs(true);
  };

  const handlecustomerNameChange = (e) => {
    setCustomerName(e.target.value);
  };

  const handleSaveCustomer = async () => {
    if (customerName.trim() === "") {
      setError("Project name cannot be empty");
      return;
    }

    setLoading(true);
    setError("");

    try {
      const customerCollection = firestore.collection("customers");
      const newCustomer = {
        name: customerName,
      };

      await customerCollection.add(newCustomer);

      setCustomerName("");
      setLoading(false);
      setshowNewCustomerInputs(false);

      window.location.reload();
    } catch (err) {
      setError("An error occurred while saving the project.");
      setLoading(false);
      console.error(err);
    }
  };

  return (
    <Content>
      <h1>Kunden</h1>
      <div style={{ width: "90%" }}>
        <TableList data={customerData} columns={tableColumns} path={""} />
        <ButtonSmallLight onClick={handleNewCustomerClick}>
          Neuer Kunde
        </ButtonSmallLight>
        {showNewCustomerInputs && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              border: `1px solid ${colors.lightGrey}`,
              borderRadius: "5px",
              padding: "10px",
              marginTop: "10px",
            }}
          >
            <label htmlFor="customerName">Projekt Name:</label>
            <Input
              type="text"
              id="customerName"
              value={customerName}
              onChange={handlecustomerNameChange}
            />

            {loading ? (
              <RotatingCircle /> // Display the rotating circle when loading
            ) : (
              <ButtonLight onClick={handleSaveCustomer}>Speichern</ButtonLight>
            )}
            {error && (
              <p style={{ color: colors.red, margin: padding.small }}>
                {error}
              </p>
            )}
          </div>
        )}
      </div>

      <Logout />
    </Content>
  );
};

export default Customer;
