import React from "react";
import { Route, Routes, Link } from "react-router-dom";
import Dashboard from "./Dashboard"; // Import your Dashboard component
import Customer from "./Customer/Customer"; // Import your Dashboard component
import { ContentBody, ContentInner, Footer } from "../styledComponents/ui";
import ListMenu from "../ListMenu";
import CustomerOverView from "./Customer/CustomerOverView";
import CustomerProject from "./Customer/CustomerProject";
import Logout from "../Logout";
const Hub = ({ page }) => {
  console.log(page);
  return (
    <div>
      <ContentBody>
        <ListMenu />
        <ContentInner>
          {page === "dashboard" && <Dashboard />}
          {page === "customer" && <Customer />}
          {page === "customerOverview" && <CustomerOverView />}
          {page === "customerProject" && <CustomerProject />}
        </ContentInner>
      </ContentBody>
      <Footer>
        <Logout />
        <div style={{ marginRight: "15px" }}>© Charge Media</div>
      </Footer>
    </div>
  );
};

export default Hub;
