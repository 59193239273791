import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { StyledTable } from "./styledComponents/ui";
import ChargemediaTheme from "../ChargemediaTheme";

const { colors } = ChargemediaTheme;

const TableList = ({ data, columns, path }) => {
  const navigate = useNavigate();

  const handleRowClick = (customerId, path) => {
    if (path === "") {
      navigate(`./${customerId}`);
    } else {
      navigate(`./${path}/${customerId}`);
    }

    console.log("Row clicked:", customerId);
  };

  // Function to display UID of each document in the console
  const displayDocumentUIDs = () => {
    data.forEach((customer) => {
      console.log("Document UID:", customer.id);
    });
  };

  return (
    <StyledTable>
      <thead>
        <tr style={{ color: colors.lightGrey }}>
          {columns.map((column, index) => (
            <th key={index}>{column.header}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((customer, rowIndex) => (
          <tr key={rowIndex} onClick={() => handleRowClick(customer.id, path)}>
            {columns.map((column, colIndex) => (
              <td key={colIndex}>{customer[column.field]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

export default TableList;
