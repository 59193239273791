import React, { useEffect, useState } from "react";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import { LoginContainer, Box } from "./styledComponents/StyledLogin";
import { InputLogin, Button, RotatingCircle } from "./styledComponents/ui";
import Logo from "./ui/Logo";
import { flags } from "../config";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const Debug = flags.Debug;
  const debugEmail = "test@test.com";
  const debugPassword = "test12";

  const handleLogin = async () => {
    try {
      setLoading(true);
      await auth.signInWithEmailAndPassword(email, password);
      console.log("Login successful");
      navigate("/hub");
    } catch (error) {
      if (error.code === "auth/invalid-email") {
        setError("Invalid email format. Please enter a valid email.");
      } else {
        setError("An error occurred while logging in. Please try again.");
        console.error("Error logging in:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleLogin();
      console.log("Enter pressed");
    }
  };

  useEffect(() => {
    if (Debug) {
      setEmail(debugEmail);
      setPassword(debugPassword);
    }
  }, [Debug]);

  return (
    <LoginContainer>
      <Logo width="50%" />
      <Box>
        <InputLogin
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          onKeyDown={handleKeyPress}
        />
        <InputLogin
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          onKeyDown={handleKeyPress}
        />
        {error && <p style={{ color: "red" }}>{error}</p>}
        {loading ? (
          <RotatingCircle /> // Display the rotating circle when loading
        ) : (
          <Button onClick={handleLogin}>Login</Button>
        )}
      </Box>
    </LoginContainer>
  );
}

export default Login;
