import React, { useState, useEffect } from "react";
import CalendarComponent from "./CalendarComponent";
import { Content } from "./styledComponents/ui";
import { firestore } from "../firebase";
import { ButtonSmallLight, Input, RotatingCircle } from "./styledComponents/ui";
import DayCalendar from "./DayCalendar";
const TimeTracker = ({ customerPjRef, timerData }) => {
  const [name, setName] = useState("");
  const [startTime, setStartTime] = useState(null);
  const [stopTime, setStopTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [isRunning, setIsRunning] = useState(false);
  const [timerStarted, setTimerStarted] = useState(false);
  const [canSave, setCanSave] = useState(false);
  const [loading, setLoading] = useState(false);
  const [elapsedTimeAtStop, setElapsedTimeAtStop] = useState(0);
  const [documentId, setDocumentId] = useState("");
  const [nameError, setNameError] = useState("");

  useEffect(() => {
    if (isRunning) {
      const interval = setInterval(() => {
        setElapsedTime(Date.now() - startTime);
      }, 1000);

      return () => clearInterval(interval);
    }
  }, [isRunning, startTime]);

  const formatTime = (time) => {
    const hours = Math.floor(time / 3600000);
    const minutes = Math.floor((time % 3600000) / 60000);
    const seconds = Math.floor((time % 60000) / 1000);

    return `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;
  };
  const formatDateTime = (timestamp) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const startTimer = () => {
    if (!timerStarted) {
      setStartTime(Date.now());
      setStopTime(null);
      setIsRunning(true);
      setTimerStarted(true);
      setCanSave(true);
    }
  };

  const stopTimer = async () => {
    const stopTime = Date.now();
    setStopTime(Date.now());
    setIsRunning(false);
    setTimerStarted(false);
    saveToFirestore(stopTime);
  };

  const resetTimer = () => {
    setStartTime(null);
    setStopTime(null);
    setElapsedTime(0);
    setIsRunning(false);
    setTimerStarted(false); // Reset the timer started state
  };

  const [editMode, setEditMode] = useState(false);
  const [editedTime, setEditedTime] = useState(formatTime(elapsedTime));

  const startEditing = () => {
    if (!isRunning) {
      setEditedTime(formatTime(elapsedTime));
      setEditMode(true);
    }
  };

  const cancelEditing = () => {
    setEditMode(false);
  };

  const applyEditedTime = () => {
    editTimer(editedTime);
    setEditMode(false);
  };

  const editTimer = (newTime) => {
    if (!isRunning) {
      const [hours, minutes, seconds] = newTime.split(":").map(Number);
      const newElapsedTime = (hours * 3600 + minutes * 60 + seconds) * 1000;
      setStopTime(stopTime - elapsedTime + newElapsedTime);
      setElapsedTime(newElapsedTime);
    }
  };

  const currentTime = isRunning ? Date.now() : stopTime;

  const saveToFirestore = async (stopTime) => {
    setLoading(true);
    try {
      if (!name) {
        setNameError("Name is required.");
        setLoading(false);
        return;
      }

      const timerData = {
        name: name,
        startTime: formatDateTime(startTime), // Convert to formatted string
        stopTime: formatDateTime(stopTime), // Convert to formatted string
        elapsedTime: elapsedTime,
      };

      // Fetch existing timer data from Firestore
      const snapshot = await customerPjRef.get();
      let existingTimerData = [];

      if (snapshot.exists) {
        existingTimerData = snapshot.data().timerData || [];
      }

      // Append the new timer data to the existing array
      existingTimerData.push(timerData);

      // Update the Firestore document with the updated array
      await customerPjRef.set(
        { timerData: existingTimerData },
        { merge: true }
      );

      setNameError("");
      setLoading(false);
      setDocumentId("Last entry");
      console.log("Timer data saved to Firestore");
      window.location.reload();
    } catch (error) {
      console.error("Error saving timer data:", error);
    }
  };
  return (
    <Content>
      <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
        <div>
          <h2>Zeiterfassung</h2>
          <label>Name: </label>
          <Input
            type="text"
            placeholder="Timer Name"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
              setNameError(""); // Clear error message when input changes
            }}
          />
          {nameError && <div style={{ color: "red" }}>{nameError}</div>}

          {startTime && <div>Start Time: {formatDateTime(startTime)}</div>}
          {stopTime && <div>Stop Time: {formatDateTime(stopTime)}</div>}
          <div>
            Vergangene Zeit: <div>{formatTime(elapsedTime)}</div>
          </div>
          <ButtonSmallLight
            onClick={startTimer}
            disabled={timerStarted || canSave}
          >
            Start
          </ButtonSmallLight>
          {documentId && (
            <div>
              Gespeichert, Seite wird Neu Geladen
              <RotatingCircle /> {documentId}
            </div>
          )}
          {loading ? (
            <RotatingCircle /> // Display the rotating circle when loading
          ) : (
            <ButtonSmallLight onClick={stopTimer} disabled={!canSave}>
              Speichern
            </ButtonSmallLight>
          )}
        </div>

        <div>
          <DayCalendar timerData={timerData} />
        </div>

        {/*   <CalendarComponent currentTime={currentTime} />*/}
      </div>
    </Content>
  );
};

export default TimeTracker;
