import React from "react";
import styled from "styled-components";
import ChargemediaTheme from "../../ChargemediaTheme";
import { ButtonSmallLight } from "../styledComponents/ui";
const { colors } = ChargemediaTheme;
const PopupContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);
`;

const PopupBox = styled.div`
  background-color: ${colors.darkGrey};
  padding: 16px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
`;

const PopupTitle = styled.h2`
  margin: 0 0 16px;
`;

const PopupButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

const PopupButton = styled.button`
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
`;

const CustomPopup = ({ title, message, onConfirm, onCancel }) => {
  return (
    <PopupContainer>
      <PopupBox>
        <PopupTitle>{title}</PopupTitle>
        <p>{message}</p>
        <PopupButtons>
          <ButtonSmallLight onClick={onCancel}>Cancel</ButtonSmallLight>
          <ButtonSmallLight onClick={onConfirm}>Confirm</ButtonSmallLight>
        </PopupButtons>
      </PopupBox>
    </PopupContainer>
  );
};

export default CustomPopup;
