import React, { useState } from "react";
import styled from "styled-components";
import { firestore } from "../../firebase";
import { ButtonSmallLight, Input } from "../styledComponents/ui";
import CustomPopup from "./CustomPopup";
import ChargemediaTheme from "../../ChargemediaTheme";

const { colors } = ChargemediaTheme;

const TimeTrackerContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 8px;
`;

const ItemContainer = styled.div`
  flex: 1;
  margin-right: 16px;
`;

const Label = styled.p`
  font-weight: bold;
  margin-bottom: 4px;
`;

const Value = styled.p`
  margin: 0;
`;

const formatTime = (milliseconds) => {
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);

  const formattedTime = `${hours}h ${minutes % 60}m ${seconds % 60}s`;
  return formattedTime;
};

const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  const formattedDate = date.toLocaleString(); // You can adjust the formatting as needed
  return formattedDate;
};
const TimeTrackerComp = ({ timerData, customerPjRef }) => {
  const [editedData, setEditedData] = useState(
    new Array(timerData.length).fill({
      name: false,
      startTime: false,
      stopTime: false,
    })
  );
  const [inputErrors, setInputErrors] = useState(
    new Array(timerData.length).fill(false)
  );

  const totalElapsedTime = timerData.reduce(
    (sum, timerItem) => sum + timerItem.elapsedTime,
    0
  );

  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [timerToDelete, setTimerToDelete] = useState(null);

  const handleDeleteTimer = (timerId) => {
    setTimerToDelete(timerId);
    setShowDeletePopup(true);
  };

  const confirmDeleteTimer = async () => {
    try {
      setShowDeletePopup(false);

      const updatedTimerData = timerData.filter(
        (_, index) => index !== timerToDelete
      );

      await customerPjRef.update({
        timerData: updatedTimerData,
      });

      console.log("Timer deleted successfully");
      window.location.reload();
    } catch (error) {
      console.error("Error deleting timer:", error);
    }
  };

  const cancelDeleteTimer = () => {
    setShowDeletePopup(false);
    setTimerToDelete(null);
  };

  const handleEditTimer = (timerId) => {
    setEditedData((prevData) => {
      const newData = [...prevData];
      newData[timerId] = {
        name: timerData[timerId].name,
        startTime: timerData[timerId].startTime,
        stopTime: timerData[timerId].stopTime,
        isEditing: true,
      };
      return newData;
    });
  };

  const handleSaveChanges = async (timerId) => {
    try {
      const editedStartTime = new Date(editedData[timerId].startTime);
      const editedStopTime = new Date(editedData[timerId].stopTime);
      const editedElapsedTime = editedStopTime - editedStartTime;

      // Validate input for the edited data
      if (
        !editedData[timerId].name ||
        !editedData[timerId].startTime ||
        !editedData[timerId].stopTime
      ) {
        setInputErrors((prevErrors) => {
          const newErrors = [...prevErrors];
          newErrors[timerId] = true;
          return newErrors;
        });
        return;
      }

      // Update timer data for the specific timer

      const updatedTimerData = timerData.map((item, index) => {
        if (index === timerId) {
          return {
            ...item,
            name: editedData[timerId].name,
            startTime: editedData[timerId].startTime,
            stopTime: editedData[timerId].stopTime,
            elapsedTime: editedElapsedTime, // Update elapsed time
          };
        }
        return item;
      });

      // Update the timer data in Firestore
      await customerPjRef.update({
        timerData: updatedTimerData,
      });

      // Reset the editing state
      setEditedData((prevData) => {
        const newData = [...prevData];
        newData[timerId] = {
          name: false,
          startTime: false,
          stopTime: false,
          isEditing: false,
        };
        return newData;
      });

      console.log("Timer edited successfully");
      window.location.reload();
    } catch (error) {
      console.error("Error editing timer:", error);
    }
  };

  return (
    <div>
      {timerData.map((timerItem, index) => (
        <TimeTrackerContainer key={index}>
          <ItemContainer>
            <Label>Name</Label>
            {editedData[index].name ? (
              <Input
                type="text"
                value={editedData[index].name}
                onChange={(e) =>
                  setEditedData((prevData) => {
                    const newData = [...prevData];
                    newData[index].name = e.target.value;
                    return newData;
                  })
                }
              />
            ) : (
              <Value>{timerItem.name}</Value>
            )}
            {inputErrors[index] && (
              <p style={{ color: "red" }}>All fields must be filled</p>
            )}
          </ItemContainer>
          <ItemContainer>
            <Label>Elapsed Time</Label>
            <Value>
              {editedData[index].isEditing
                ? formatTime(
                    new Date(editedData[index].stopTime) -
                      new Date(editedData[index].startTime)
                  )
                : formatTime(timerItem.elapsedTime)}
            </Value>
          </ItemContainer>
          <ItemContainer>
            <Label>Start Zeit</Label>
            {editedData[index].startTime ? (
              <Input
                type="datetime-local"
                value={editedData[index].startTime}
                onChange={(e) =>
                  setEditedData((prevData) => {
                    const newData = [...prevData];
                    newData[index].startTime = e.target.value;
                    return newData;
                  })
                }
              />
            ) : (
              <Value>{formatDate(timerItem.startTime)}</Value>
            )}
          </ItemContainer>
          <ItemContainer>
            <Label>Stop Zeit</Label>
            {editedData[index].stopTime ? (
              <Input
                type="datetime-local"
                value={editedData[index].stopTime}
                onChange={(e) =>
                  setEditedData((prevData) => {
                    const newData = [...prevData];
                    newData[index].stopTime = e.target.value;
                    return newData;
                  })
                }
              />
            ) : (
              <Value>{formatDate(timerItem.stopTime)}</Value>
            )}
          </ItemContainer>
          <ItemContainer>
            {editedData[index].name ||
            editedData[index].startTime ||
            editedData[index].stopTime ? (
              <>
                <ButtonSmallLight onClick={() => handleSaveChanges(index)}>
                  Speichern
                </ButtonSmallLight>
                <ButtonSmallLight
                  onClick={() =>
                    setEditedData((prevData) => {
                      const newData = [...prevData];
                      newData[index] = {
                        name: false,
                        startTime: false,
                        stopTime: false,
                      };
                      return newData;
                    })
                  }
                >
                  Kündigen
                </ButtonSmallLight>
              </>
            ) : (
              <>
                <ButtonSmallLight onClick={() => handleDeleteTimer(index)}>
                  Löschen
                </ButtonSmallLight>
                <ButtonSmallLight onClick={() => handleEditTimer(index)}>
                  Bearbeiten
                </ButtonSmallLight>
              </>
            )}
          </ItemContainer>
        </TimeTrackerContainer>
      ))}
      <TimeTrackerContainer>
        <ItemContainer>
          <Label>Total Elapsed Time</Label>
          <Value>{formatTime(totalElapsedTime)}</Value>
        </ItemContainer>
      </TimeTrackerContainer>
      {showDeletePopup && (
        <CustomPopup
          title="Delete Timer"
          message="Are you sure you want to delete this timer?"
          onConfirm={confirmDeleteTimer}
          onCancel={cancelDeleteTimer}
        />
      )}
    </div>
  );
};

export default TimeTrackerComp;
