import React from "react";
import { Link } from "react-router-dom";
import { StyledMenuItem } from "../styledComponents/StyledHub";
import styled from "styled-components";

const StyledLink = styled(Link)`
  text-decoration: none; /* Remove underline */
  color: inherit; /* Inherit text color */
`;
const MenuItem = ({ to, label }) => {
  return (
    <div>
      <StyledLink to={to} className="menu-link">
        <StyledMenuItem>{label}</StyledMenuItem>
      </StyledLink>
    </div>
  );
};

export default MenuItem;
