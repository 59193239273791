import React from "react";
import { ListMenuStyle } from "./styledComponents/StyledHub";
import MenuItem from "./ui/MenuItem";
import Logo from "./ui/Logo";
const ListMenu = () => {
  return (
    <ListMenuStyle>
      <Logo width="75%" />
      <MenuItem to="/hub" label="Dashboard" />
      <MenuItem to="/hub/c" label="Zeiterfassung" />
      {/* Add more menu items as needed */}
    </ListMenuStyle>
  );
};

export default ListMenu;
