import React from "react";
import styled from "styled-components";
import ChargemediaTheme from "../../ChargemediaTheme";

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 8px; /* Add some margin to separate logo from the box */
`;

const LogoImage = styled.img`
  width: ${(props) => (props.width ? props.width : "100%")};
`;

function Logo(props) {
  return (
    <LogoContainer>
      <LogoImage src="/img/logo.png" alt="Logo" width={props.width} />
    </LogoContainer>
  );
}

export default Logo;
