import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCHcZgWMNcztxZtLto-7hQT0atsrd9linM",
  authDomain: "cm-tools-53d35.firebaseapp.com",
  projectId: "cm-tools-53d35",
  storageBucket: "cm-tools-53d35.appspot.com",
  messagingSenderId: "34232002659",
  appId: "1:34232002659:web:aedcd0320f5632bf3e7056",
};

firebase.initializeApp(firebaseConfig);

export const firestore = firebase.firestore();
export const auth = firebase.auth();
