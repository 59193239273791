import React, { useState, useEffect } from "react";
import { auth, firestore } from "../../../firebase";
import Logout from "../../Logout";
import { Content, OverViewBox } from "../../styledComponents/ui";
import BoxContentUI from "../../BoxContentUI";
import TableList from "../../TableList";

const CustomerOverView = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [customerData, setCustomerData] = useState(null);
  const [customerPjData, setCustomerPjData] = useState(null);
  const [documentId, setDocumentId] = useState(null); // Define documentId state
  const tableColumns = [
    { header: "Name", field: "name" },

    // Add more columns as needed
  ];
  // Implement a function to extract the document ID from the URL
  const getDocumentIdFromURL = () => {
    const url = window.location.href;
    const parts = url.split("/");
    return parts[parts.length - 1];
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });

    const idFromURL = getDocumentIdFromURL();
    setDocumentId(idFromURL);

    if (idFromURL) {
      const customerRef = firestore.collection("customers").doc(idFromURL);
      const customerPjRef = firestore
        .collection("customers")
        .doc(idFromURL)
        .collection("projects");

      customerRef.get().then((doc) => {
        if (doc.exists) {
          setCustomerData(doc.data());
        } else {
          console.log("Document not found");
        }

        customerPjRef.get().then((snapshot) => {
          const customerPjData = [];
          snapshot.forEach((doc) => {
            const data = doc.data();
            // Store the document ID along with the data
            customerPjData.push({ id: doc.id, ...data });
          });
          setCustomerPjData(customerPjData);
        });
      });
    }

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <Content>
      <h1>Kunden OVERVIEW</h1>
      {customerData && (
        <div>
          <p>Customer Name: {customerData.name}</p>
          {customerPjData && customerPjData.length > 0 && <div></div>}
        </div>
      )}
      {documentId && (
        <BoxContentUI title="Projekte" isEditable={true} DocID={documentId}>
          {customerPjData !== null && customerPjData.length > 0 && (
            <TableList
              data={customerPjData}
              columns={tableColumns}
              path={"pj"}
            />
          )}
        </BoxContentUI>
      )}
    </Content>
  );
};

export default CustomerOverView;
