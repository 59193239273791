const ChargemediaTheme = {
  colors: {
    lightGrey: "#D9D9D9",
    darkGrey: "#292C2C",
    accentGrey: "#3b3b3b",
    red: "#C0392B",
    green: "#2ecc71",
    greenHover: "#27ae60",
    // Add more colors as needed
  },
  padding: {
    small: "15px",
    mobileSmall: "8px",
    medium: "30px",
    large: "145px",
  },
  timings: {
    hover: "0.275s",
  },
  // Add the mode property
  mode: "light", // or "dark"
};

export default ChargemediaTheme;
