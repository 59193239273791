import React, { useState, useEffect } from "react";
import { auth } from "../../firebase";
import Logout from "../Logout";
import { Button } from "@mui/material";
import { useTheme } from "styled-components"; // Import useTheme

const Dashboard = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const theme = useTheme(); // Get the custom theme from styled-components

  useEffect(() => {
    // Listen for changes in the authentication state
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
  }, []);

  return (
    <div>
      <h1>Dashboard</h1>
      {currentUser && <p>Welcome, {currentUser.email}!</p>}
    </div>
  );
};

export default Dashboard;
