import React from "react";
import Calendar from "react-calendar";
import { styled } from "styled-components";
import "react-calendar/dist/Calendar.css";

const CalendarContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 45%;
  .react-calendar {
    width: 50%;
    height: 30%;
    border: none;
    border-radius: 0;
    box-shadow: none;

    font-size: 18px;
    font-weight: 400;
    line-height: 1;
    color: #333;
    background: #fff;
    box-sizing: border-box;
  }
`;

const DayCalendar = ({ timerData }) => {
  // Create an object to store events by date
  const eventsByDate = {};

  // Group events by date
  timerData.forEach((timerItem) => {
    const date = new Date(timerItem.startTime).toDateString(); // Convert to date string to group events by date
    if (!eventsByDate[date]) {
      eventsByDate[date] = [];
    }
    eventsByDate[date].push(timerItem.name);
  });

  return (
    <CalendarContainer>
      <Calendar
        tileContent={({ date }) => {
          const eventNames = eventsByDate[date.toDateString()];

          return eventNames ? (
            <div>
              {eventNames.map((eventName, index) => (
                <div key={index}>{eventName}</div>
              ))}
            </div>
          ) : null;
        }}
      />
    </CalendarContainer>
  );
};

export default DayCalendar;
